import axios from 'axios';

class HttpRequest {
  constructor(baseUrl) {
    this.baseUrl = baseUrl;
    this.queue = {};
  }
  getInsideConfig = async ()=> {
    const config = {
      baseURL: this.baseUrl,
      timeout:3000,
      headers: {
        'content-type': 'application/json',
        //
      },
    };
    return config;
  }
  destroy(url) {
    delete this.queue[url];
    if (!Object.keys(this.queue).length) {
      // Spin.hide()
    }
  }
  interceptors(instance, url) {
    // 请求拦截
    instance.interceptors.request.use(
      config => {
        // 添加全局的loading...
        if (!Object.keys(this.queue).length) {
          // Spin.show() // 不建议开启，因为界面不友好
        }
        this.queue[url] = true;
        return config;
      },
      error => {
        return Promise.reject(error);
      },
    );
    // 响应拦截
    instance.interceptors.response.use(
      res => {
        this.destroy(url);
        var data = res.data;
        return data;//请求成功之后将返回值返回
      },
      error => {
        this.destroy(url);
        console.log('服务器连接异常')
        return Promise.reject(error);
      },
    );
  }
  request = async (options)=> {
    const instance=axios.create();
    let data = options.data||{};
    if(options.method.toLowerCase()=='get') {
      options.params = {
        ...data,
        datatype: 'json',
      };
    } 
    let getInsideConfig = await this.getInsideConfig()
    options = Object.assign( getInsideConfig, options);
    this.interceptors(instance, options.url);
    return instance(options);
  }
}
export default HttpRequest;
