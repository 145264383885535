import axiosJava from './api.request.java';

/**
 * @description 供应商列表
 */
export const slotCard = (data) => {
  return axiosJava.request({
    url: '/tab-business-collect-entity/slotCard',
    method: 'post',
    data
  });
};

/**
 * @description 通用图片上传
 */
export const upload = (data) => {
  return axiosJava.request({
    url: '/pic/upload',
    method: 'post',
    data
  });
};

